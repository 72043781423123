import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import AuthorExpanded from '@widgets/AuthorExpanded'
import { useBlogAuthors } from '@helpers-blog'

const PageAuthors = props => {
  const authors = useBlogAuthors()

  return (
    <Layout {...props}>
      <Seo title='Our Team' />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='Team Members'
          subheader='Whether it is reviewing the latest gadgets or exploring the cutting-edge advancements in artificial intelligence, our authors are always on the forefront of what is happening in the tech industry. We strive to deliver accurate, insightful, and engaging content that will inform and inspire our readers.'
          running='At Tech Square, you can trust that our writers are true experts in their field, with the knowledge and expertise to bring you the best possible content. So whether you are a tech enthusiast or just curious about the latest trends, you can count on Tech Square to keep you up-to-date and in the know.'
        />
      </Stack>
      <Stack>
        <Main>
          {authors.map((author, i) => (
            <React.Fragment key={`item-${i}`}>
              <Divider />
              <AuthorExpanded author={author} withLink />
            </React.Fragment>
          ))}
        </Main>
      </Stack>
    </Layout>
  )
}

export default PageAuthors
